import { googleAnalytics } from './modules/google_analytics/';


// POLYFILL FOR CLOSEST
// #######################################################################################
// #######################################################################################
if (!Element.prototype.matches)
    Element.prototype.matches = Element.prototype.msMatchesSelector ||
                                Element.prototype.webkitMatchesSelector;
if (!Element.prototype.closest)
    Element.prototype.closest = function(s) {
        var el = this;
        if (!document.documentElement.contains(el)) return null;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
// #######################################################################################
// #######################################################################################


// set position of map based on screen width on intial load

if(window.innerWidth > 885){
  var longitude = -80.175;
  var latitude = 25.79;
  var zoom = 12;
}else{
  var longitude = -80.150;
  var latitude = 25.76;
  var zoom = 11;
}


mapboxgl.accessToken = 'pk.eyJ1IjoibWF6emkiLCJhIjoiY2pwMWI5ODRwM2UycTNwa3d0ajRuaTgyNiJ9.QTI-8BRudv4C_F1YMaLoWg';

  var map = new mapboxgl.Map({
    container: 'map',
    style: 'mapbox://styles/mazzi/cjp1f12yh14ey2sqonb89t6h2',
    center: [longitude, latitude],
    zoom: zoom
  });

  map.addControl(new mapboxgl.NavigationControl());

  var geojson = {
    type: 'FeatureCollection',
      features: [{
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-80.1695, 25.7429]
      },
      properties: {
        title: 'Miami International Boat Show ',
        description: '3501 Rickenbacker Causeway, <br> Miami, FL 33149',
        marker_img: 'pin_miami_boat_show_2020_center.svg',
        iconSize: [725, 104]
      }
    },
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [-80.1859, 25.7889]
      },
      properties: {
        title: ' Miami Yacht Show',
        description: '1 Herald Plaza, <br> Miami, FL 33132',
        marker_img: 'pin_miami_yacht_show_2020_center.svg',
        iconSize: [489, 104]
      }
    }]
  };


  // add markers to map
  geojson.features.forEach(function(marker) {

    // create a HTML element for each feature
    // var el = document.createElement('div');
    // el.className = marker.properties.marker_img;

    console.log(marker);

    var el = document.createElement('div');
      el.className = 'marker';
      // el.style.backgroundImage = 'url(https://placekitten.com/g/' + marker.properties.iconSize.join('/') + '/)';
      el.style.backgroundImage = 'url(img/'+ marker.properties.marker_img +')';
      el.style.width = marker.properties.iconSize[0] + 'px';
      el.style.height = marker.properties.iconSize[1] + 'px';

    // make a marker for each feature and add to the map
    new mapboxgl.Marker(el)
    .setLngLat(marker.geometry.coordinates)
    .addTo(map);

    new mapboxgl.Marker(el)
    .setLngLat(marker.geometry.coordinates)
    .setPopup(new mapboxgl.Popup({ offset: 25 }) // add popups
    .setHTML('<h3>' + marker.properties.title + '</h3><p>' + marker.properties.description + '</p>'))
    .addTo(map);
  });





var faq_more_btn = document.querySelectorAll('.question');

for (var i = 0; i < faq_more_btn.length; i++) {

  faq_more_btn[i].addEventListener('click', function(event) {
    var question = event.target.closest('.question');
    showMoreBtn(question);
  })

}


function showMoreBtn(dom){

  let isOpen = classExist(dom, 'open');

  if(isOpen){
    closeDom(dom);
  }else{
    openDom(dom);
  }

}


function classExist(dom, domClass){
  return dom.classList.contains(domClass);
}

function openDom(dom){
    dom.classList.remove('close');
    dom.classList.add('open');
}

function closeDom(dom){
  dom.classList.remove('open');
  dom.classList.add('close');
}


// GET DATA OBJECT for click events
// ################################


var whole_site = document.querySelectorAll('.campaign_container');
whole_site[0].addEventListener('click', heat_map_data);

function heat_map_data(event){

  if(currentPosition()){

    var jsonData = JSON.stringify( currentPosition());
    console.log(jsonData);

    googleAnalytics('heatmap', 'pos', jsonData)

  }
}



function currentPosition(){

  var pos = { x: contentPosX(),
              y: contentPosY(),
              currentwidth: screenWidth()
            };

  if(pos.x){
    return pos;
  }else{
    return false;
  }
}



// function contentPosX(){
//   var offsetLeft = offsetLeftLayout();
//   var positionX;

//   // get position if over content
//   if(offsetLeft){
//     positionX = event.pageX - offsetLeft;
//   }else{
//     positionX = event.pageX;
//   }

//   // only send position if in layout
//   if(positionX > 0 && positionX < 1024 ){
//     return positionX;
//   }else{
//     return null;
//   }
// }

function contentPosX(){
  return event.pageX;
}

function contentPosY(){
  return event.pageY;
}


// function offsetLeftLayout(){
//   var layoutMaxWidth = 1024;
//   var currentWidth = screenWidth();

//   if(currentWidth > layoutMaxWidth){
//     return (currentWidth - layoutMaxWidth) / 2;
//   }else{
//     return 0;
//   }
// }


function screenWidth(){
  var body = document.body;
  var html = document.documentElement;
  var width = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);

  return width;
}





