
function googleAnalytics(cat, action, label) {

  gtag('event', 'custom_event', {
      event_category: cat,
      event_action: action,
      event_label: label,
    });

  // console.log('random in', cat, action, label);
}

export {
  googleAnalytics
};
